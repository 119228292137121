import { Component, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { selectExtraData } from '../../../../state'

import { BaseArticleComponent } from 'src/app/shared/components/base-article-component/base-article-component'
import { Instrument } from '../instruments-article.model'
import { Dictionary } from '@ngrx/entity'
import { MixpanelService } from 'src/app/services/mixpanel.service'
import { AsyncPipe } from '@angular/common'
import { InstrumentViewComponent } from '../../../../shared/components/instrument-view/instrument-view.component'
import { NgScrollbarModule } from 'ngx-scrollbar'

@Component({
    selector: 'app-instrument-article',
    templateUrl: './instrument-article.component.html',
    styleUrls: ['./instrument-article.component.scss'],
    standalone: true,
    imports: [NgScrollbarModule, InstrumentViewComponent, AsyncPipe]
})
export class InstrumentArticleComponent extends BaseArticleComponent<Instrument>  {

    public extraData$ : Observable<Dictionary<any>> = new Observable

    constructor() {
        super()
        this.area = 'data/instruments'
        this.extraData$ = this.store.select(selectExtraData)
    }
}