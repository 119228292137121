@if (article$ | async; as instrument) {

    @if (instrument.extras) {
<ng-scrollbar class="ngr-scrollbar"  visibility="visible">
        @if ((extraData$ | async); as extraData) {

            <h2>{{instrument.description}} – {{instrument.title}} </h2>
            <app-instrument-view [instrument]="instrument.extras" [instrumentIDLookup]="extraData['instrumentIDLookup']" ></app-instrument-view>

}
    </ng-scrollbar>
}

}
